import React from 'react';
import { Router, Switch, Route } from 'react-router-dom';
import { ApolloProvider, Query } from 'react-apollo';
import { ThemeProvider } from 'styled-components';
import styled from 'styled-components';
import { ParallaxProvider } from 'react-scroll-parallax';
import { Head } from 'react-static';
import { withTranslation } from 'react-i18next';

import client from 'core/api/apollo/init';
import { CURRENT_USER } from 'core/api/apollo/queries';
import theme from '../theme';
import GlobalStyles from '../GlobalStyles';
import GenericRoute from '../components/GenericRoute';
import NotFound from 'core/ui/pages/NotFound';
import history from 'core/api/history';
import { AccountProvider } from 'core/api/accounts/accountContext';
import { InAppNotificationProvider } from 'core/api/toast/inAppNotificationsContext';
import Toast from 'core/ui/components/Toast';
import BgContainer from '../components/BgContainer';

import coreRoutes from 'core/api/routes';

const combinedRoutes = [...coreRoutes];

const AppRoot = ({ t }) => {
  return (
    <ApolloProvider client={client}>
      <InAppNotificationProvider>
        <ThemeProvider theme={theme}>
          <Router history={history}>
            <Query query={CURRENT_USER}>
              {({ error, data, loading: userLoading }) => {
              if (error) {
                console.log(error);
                // return <ErrorOccurred error={error}/>;
              }
              return (
                <AccountProvider
                  value={{
                    currentUser: (data || {}).me,
                    userLoading,
                  }}
                >
                  <ParallaxProvider>
                    <Head>
                      <title>{t('html.title')}</title>
                      <meta name="description" content="40-day meditation retreat in your smartphone" />
                      <meta property="og:description" content="40-day meditation retreat in your smartphone" />
                      <meta property="twitter:description" content="40-day meditation retreat in your smartphone" />
                      <meta property="og:title" content="Sati" />
                      <meta property="twitter:title" content="Sati" />
                      <meta property="og:image" content="https://dj6wjgdqr93aw.cloudfront.net/og_image.jpg" />
                      <meta property="twitter:image" content="https://dj6wjgdqr93aw.cloudfront.net/og_image.jpg" />
                      <meta property="og:site_name" content="Sati" />
                      <meta property="og:url" content="https://sati.app" />
                      <meta property="twitter:url" content="https://sati.app" />
                    </Head>
                    <div className="App">
                      <Overflow>
                        <BgContainer>
                          <Toast />
                          <Switch>
                            {combinedRoutes.map(routeData =>
                              <GenericRoute key={routeData.path} {...routeData} />)}
                            <Route component={NotFound} />
                          </Switch>
                          <GlobalStyles />
                        </BgContainer>
                      </Overflow>
                    </div>
                  </ParallaxProvider>
                </AccountProvider>
              );
            }}
            </Query>
          </Router>
        </ThemeProvider>
      </InAppNotificationProvider>
    </ApolloProvider>
  );
}

const Overflow = styled.div`
  overflow: hidden;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.3);
`;

export default withTranslation()(AppRoot);