import React, { useEffect } from 'react';
import styled, { css } from 'styled-components';
import { withTranslation } from 'react-i18next';
import AppleSignin from 'react-apple-signin-auth';
import { useMutation } from '@apollo/react-hooks';
import jwt_decode from 'jwt-decode';
import { useLocation } from 'react-router-dom';

import { SectionWrap, Logo, Text } from 'core/ui/atoms';
import SignInForm from 'core/ui/components/SignInForm';
import GetAppButton from 'core/ui/components/GetAppButton';
import { WEB_SIGN_IN_WITH_GOOGLE, WEB_SIGN_IN_WITH_APPLE } from 'core/api/apollo/mutations';
import { loginWithToken } from 'core/api/actions';
import { withInAppNotifications } from 'core/api/toast/withInAppNotifications';
// import NY from 'core/ui/components/NY';

// import BLACK_FRIDAY_IMAGE from '../../assets/images/black_friday.png';

const SignIn = ({ t, showNotification }) => {
  const currentLocation = useLocation();

  const [webSignInWithGoogle] = useMutation(WEB_SIGN_IN_WITH_GOOGLE);
  const [webSignInWithApple] = useMutation(WEB_SIGN_IN_WITH_APPLE);

  useEffect(() => {
    const forceSign = async () => {
      const params = new URLSearchParams(currentLocation.search);
      const authToken = params.get('authToken');
      console.log('authToken', authToken);
      if (authToken) {
        await loginWithToken(authToken);
      }
    };
    forceSign();
  }, [currentLocation.search]);

  const handleGoogleSignIn = async response => {
    const googleUser = jwt_decode(response.credential);
    let authResponse = null;
    try {
      authResponse = await webSignInWithGoogle({
        variables: {
          token: response.credential,
          userId: googleUser.sub
        }
      });
    } catch (e) {
      // console.log(e);
      showNotification({
        title: t('Notifications.GoogleAccount'),
        message: t('Notifications.AuthError'),
        type: 'ERROR',
      });
    }
    const authToken = authResponse?.data?.webSignInWithGoogle?.token;
    if (authToken) {
      await loginWithToken(authToken);
    }
  };

  const onAppleAuthSuccess = async (response) => {
    console.log('ON APPLE AUTH');
    const token = response?.authorization?.id_token;
    const appleUser = jwt_decode(token);
    try {
      if (token) {
        const authResponse = await webSignInWithApple({
          variables: {
            token,
            userId: appleUser.sub
          }
        });
        const authToken = authResponse?.data?.webSignInWithApple?.token;
        if (authToken) {
          await loginWithToken(authToken);
        }
      }
    } catch(e) {
      // console.log(e);
      showNotification({
        title: t('Notifications.AppleAccount'),
        message: t('Notifications.AuthError'),
        type: 'ERROR',
      });
    }
  }

  useEffect(() => {
    if (global?.google?.accounts?.id?.initialize) {
      global.google.accounts.id.initialize({
        client_id: '959068661859-9c8f5tcq9lin1g0t5m9kqtnh1cdnr1mj.apps.googleusercontent.com',
        callback: handleGoogleSignIn
      });
    }
    if (global?.google?.accounts?.id?.renderButton) {
      global.google.accounts.id.renderButton(
        document.getElementById('googleSignInDiv'),
        { theme: 'outline', size: 'large' }
      );
    }
  }, []);

  return (
    <ContentContainer>
      <Logo />
      <SectionWrap>
        <SignInForm />
      </SectionWrap>
      <SocialSignInContainer>
        <GoogleButtonContainer>
          <div id='googleSignInDiv'></div>
        </GoogleButtonContainer>
        <AppleSignin
          authOptions={{
            clientId: 'com.microcosm.sati.payment',
            scope: 'email name',
            redirectURI: 'https://payment.sati.app',
            state: 'state',
            nonce: 'nonce',
            usePopup: true,
          }}
          uiType="light"
          className="apple-auth-btn"
          noDefaultStyle={false}
          buttonExtraChildren={t('main.continueWithApple')}
          onSuccess={onAppleAuthSuccess}
          onError={(error) => console.error(error)}
          skipScript={false}
        />
      </SocialSignInContainer>
      <BottomContainer>
        <PolicyText>{t('main.authorizationText')}</PolicyText>
        <PolicyLink href='https://help.cielomeditation.ru' target='_blank'>{t('main.authorizationText2')}</PolicyLink>
      </BottomContainer>
      <Cont>
        <PolicyText>{t('main.noAccountMessage')}</PolicyText>
      </Cont>
      <Cont isBottom>
        <GetAppButton isApple />
        <GetAppButton />
      </Cont>
      {/* <BlackFriday href={'https://help.cielomeditation.ru/#!/tab/465761256-4'} target={'__blank'}>
        <BlackFridayImage src={BLACK_FRIDAY_IMAGE} />
      </BlackFriday> */}
      {/* <NY /> */}
    </ContentContainer>
  );
}

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: space-between;
  padding-bottom: 24px;
`;

const BottomContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 12px;
  align-items: center;
`;

const PolicyText = styled(Text)`
  color: ${p => p.theme.colors.grays.light};
  margin-bottom: 0;
`;

const PolicyLink = styled.a`
  color: ${p => p.theme.colors.white};
  text-decoration: none;
  ${p => p.theme.max('sm')`
    white-space: break-spaces;
    text-align: center;
	`}
`;

const GoogleButtonContainer = styled.div`
  margin-right: 12px;
  ${p => p.theme.max('sm')`
    margin-right: 0;
    margin-top: 12px;
  `}
`;

const SocialSignInContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${p => p.theme.max('sm')`
    flex-direction: column-reverse;
  `}
  .apple-auth-btn {
    width: 216px;
    height: 42px;
  }
`;

const Cont = styled.div`
  display: flex;
  justify-content: center;
  ${p => p.isBottom && css`
    padding-top: 12px;
  `}
  a {
    text-decoration: none;
  }
`;

// const BlackFriday = styled.a`
//   position: fixed;
//   top: 0;
//   left: 0;
//   cursor: pointer;
// `;

// const BlackFridayImage = styled.img`
//   width: 200px;
//   height: 200px;
//   z-index: 9999;
//   position: relative;
// `;

export default withInAppNotifications(withTranslation()(SignIn));
